import { useEffect, useMemo, useState } from "react";
import "./stats-panel-container.css";
import SelectedFeaturesPanel from "./selected-features-panel";
import FlyToContext from "../FlyToContext";
import { useInfoContext } from "hooks/useInfo";
import countryMapping from "data/ISO_country_mapping";
import { Icon } from "@iconify/react";
import { initialYear } from "layers/layers";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { LayerGroupName, LayerName } from "types/dataModel";
import { Viewport } from "types/map";

function Title({
  studyUnitIds,
  locations,
}: {
  studyUnitIds: string[];
  locations: string[];
}) {
  function countryNameOverride(country: string) {
    let return_country;
    switch (country) {
      case "Coted Ivoire":
        return_country = "Cote d'Voire";
        break;
      case "United Republicof Tanzania":
        return_country = "United Republic of Tanzania";
        break;
      default:
        return_country = country;
        break;
    }
    return return_country;
  }

  const countries = locations
    .map((l) =>
      l
        // insert a space before all caps
        .replace(/([A-Z])/g, " $1")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        }),
    )
    // the first character was already capitalized, so remove that space
    .map((l) => l.slice(1))
    // Convert to display names with manual override where necessary
    .map((l) => countryNameOverride(l));

  const showLocationsTooltip = false;

  const formattedCountryList = useMemo(() => {
    if (countries.length === 1) return countries[0];
    if (countries.length === 2) return `${countries[0]} and ${countries[1]}`;
    return `${countries[0]}, ${countries[1]} and ${countries.length - 2} other${
      countries.length - 2 > 1 ? "s" : ""
    }`;
  }, [countries]);

  const formattedLocationList = useMemo(() => {
    if (studyUnitIds.length === 1)
      return `1 Study Unit in ${formattedCountryList}`;

    return `${studyUnitIds.length} Study Units in ${formattedCountryList}`;
  }, [studyUnitIds, formattedCountryList]);

  return (
    <>
      <div className="stats-panel-title">
        <p>Showing statistics for:</p>
        <p
          className={`${
            studyUnitIds.length > 1 ? "body" : "body-large"
          } italic whitespace-nowrap overflow-hidden text-ellipsis lining-nums`}
        >
          {formattedLocationList}
        </p>
      </div>
      {showLocationsTooltip && (
        <div
          className="stats-panel-locations-tooltip"
          style={{ right: 20, top: 50 }}
        >
          {countries.map((l) => (
            <>
              <div>{l}</div>
              <br />
            </>
          ))}
        </div>
      )}
    </>
  );
}

function TopBanner({
  selectedFeatures,
  selectedYear,
  setSelectedYear,
  showYearOptions,
}: {
  selectedFeatures: MapboxGeoJSONFeature[];
  selectedYear: number;
  setSelectedYear: (year: number) => void;
  showYearOptions: boolean;
}) {
  const locations = [
    ...new Set(
      selectedFeatures.map(
        (x) =>
          countryMapping[x.properties?.ISO3 as keyof typeof countryMapping],
      ),
    ),
  ];

  const updateYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(e.target.value));
  };

  if (selectedFeatures.length === 0) {
    return <></>;
  }

  return (
    <div className="top-banner-container">
      <Title
        studyUnitIds={selectedFeatures.map((f) => f.id as string)}
        locations={locations}
      />
      <p className="text-right text-white mb-2 px-2 lining-nums">
        Currently viewing data for{"  "}
        {showYearOptions ? (
          <select
            value={selectedYear}
            onChange={updateYear}
            className="text-open ml-1 lining-nums rounded"
          >
            <option value={1996}>1996</option>
            <option value={2010}>2010</option>
            <option value={2015}>2015</option>
          </select>
        ) : (
          "2015"
        )}
      </p>
      {selectedYear !== initialYear && (
        <i className="text-right text-white mb-2">
          Data in the map shows 2015 values.
        </i>
      )}
      <div className="absolute !m-0 h-96 bg-open w-full" />
    </div>
  );
}

function OpenToggle({
  isOpen,
  isOtherOpen,
  setIsOpen,
  disabled,
  label,
}: {
  isOpen: boolean;
  isOtherOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  disabled: boolean;
  label: string;
}) {
  const { useFirst, selectRef } = useInfoContext() as any;
  const [showTooltip, setShowTooltip] = useState(false);
  const openTransform = {
    width: "48px",
    height: "49px",
    transform:
      isOpen || isOtherOpen
        ? "rotate(180deg) translateX(-1px)"
        : "rotate(0deg) translateX(-1px)",
    transition: "transform 0.2s ease",
  };

  useFirst(
    () => !disabled,
    "FIRST_SELECT",
    () => !!isOpen,
  );

  const onHover = () => {
    if (!disabled) return;
    setShowTooltip(true);
  };

  const onUnhover = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="open-sidebar"
      ref={selectRef}
      onMouseEnter={onHover}
      onMouseLeave={onUnhover}
    >
      <div
        className={
          "flex flex-col items-center	" + (disabled ? " opacity-50" : "")
        }
      >
        <h5 className="z-10">{label}&nbsp;&nbsp;</h5>
        <button
          data-test-id="open-metrics-button"
          className={
            "open-toggle-container" +
            (disabled
              ? ""
              : !isOpen && !isOtherOpen
              ? " coral"
              : isOpen && !isOtherOpen
              ? " cyan"
              : "")
          }
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
        >
          <Icon
            icon="ri:arrow-left-s-line"
            className="open-toggle"
            style={openTransform}
          />
        </button>
      </div>
      <div
        className={
          "absolute bg-white w-32 top-[100%]  px-2 py-1 rounded transition delay-300 duration-300 " +
          (showTooltip ? "right-0 opacity-100" : "left-[110%] opacity-0")
        }
      >
        <p className="label italic">Select a study unit to view metrics</p>
      </div>
    </div>
  );
}

export default function StatsPanel({
  selectedFeatures,
  setLayerGroup,
  flyToViewport,
  layersToggle,
  toggleLayer,
}: {
  selectedFeatures: MapboxGeoJSONFeature[];
  layersToggle: Record<string, boolean>;
  setLayerGroup: (layerGroup: LayerGroupName) => void;
  flyToViewport: (viewport: Viewport) => void;
  toggleLayer: (layer: LayerName) => void;
}) {
  const [isMetricsOpen, setIsMetricsOpen] = useState(false);
  const [isVerraOpen, setIsVerraOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(initialYear);

  // Remove this to auto-open the stats panel after the first selection
  useEffect(() => {
    if (!selectedFeatures.length) {
      setIsMetricsOpen(false);
      setIsVerraOpen(false);
    }
  }, [selectedFeatures]);

  const toggleMetrics = () => {
    setIsMetricsOpen(!isMetricsOpen);
    if (isVerraOpen) {
      setIsVerraOpen(false);
    }
  };

  const toggleVerra = () => {
    setIsVerraOpen(!isVerraOpen);
    if (isMetricsOpen) {
      setIsMetricsOpen(false);
    }
  };

  return (
    <div
      className={
        "right-panel" +
        (selectedFeatures.length && (isMetricsOpen || isVerraOpen)
          ? " open"
          : "")
      }
    >
      <div className="open-sidebar-container">
        <OpenToggle
          isOpen={isMetricsOpen}
          isOtherOpen={isVerraOpen}
          setIsOpen={toggleMetrics}
          disabled={selectedFeatures.length < 1}
          label="Metrics"
        />
        <OpenToggle
          isOpen={isVerraOpen}
          isOtherOpen={isMetricsOpen}
          setIsOpen={toggleVerra}
          disabled={selectedFeatures.length < 1}
          label="Verra"
        />
      </div>
      <div className="right-panel-content">
        <TopBanner
          selectedFeatures={selectedFeatures}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          showYearOptions={!isVerraOpen}
        />
        <div className="overflow-y-scroll">
          <FlyToContext.Provider value={{ flyToViewport, setLayerGroup }}>
            <SelectedFeaturesPanel
              selectedFeatures={selectedFeatures}
              selectedYear={selectedYear}
              mode={isMetricsOpen ? "metrics" : "verra"}
              layersToggle={layersToggle}
              toggleLayer={toggleLayer}
            />
          </FlyToContext.Provider>
        </div>
      </div>
    </div>
  );
}
