import { useMemo, useState } from "react";
import { kFormatter } from "hooks/utils/formattingUtils";
import "./selected-features-panel.css";
import "./stats-panel-container.css";

import layerGroups from "layers/layers";
import { LayerGroupName, LayerName } from "types/dataModel";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { MultiSwitch, MultiSwitchOption } from "components/multi-switch";
import popBenToMangroveRatio from "data/pop_ben_to_mangrove_ratio.json";
import stockBenToMangroveRatio from "data/stock_ben_to_mangrove_ratio.json";

function MetricContent({
  children,
  contentModifier,
}: {
  children: React.ReactNode;
  contentModifier?: string;
}) {
  return (
    <div
      className={
        "aeb-content-container " + (contentModifier ? contentModifier : "")
      }
    >
      {children}
    </div>
  );
}

function MetricTitle({
  title,
  Icon,
  selected = false,
  clickable = false,
}: {
  title: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  selected?: boolean;
  clickable?: boolean;
}) {
  return (
    <div
      className={
        "aeb-container-title" +
        (selected ? " selected" : "") +
        (clickable ? " clickable" : "")
      }
    >
      <div className="layer-image-container">
        <Icon />
      </div>
      <div className="aeb-title-text-container">
        <h4 className="text-white">{title}</h4>
      </div>
    </div>
  );
}

function TemplateMetricContainer({
  title,
  icon,
  children,
  contentModifier,
  description = "Metric specific description to come here. Only shown when section is hovered.",
}: {
  title: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  contentModifier?: string;
  description?: string;
}) {
  return (
    <div className="aeb-container text-left">
      <MetricTitle Icon={icon} title={title} />
      <MetricContent contentModifier={contentModifier}>
        <p className="aeb-container-metric-description px-2">{description}</p>
        {children}
      </MetricContent>
    </div>
  );
}

function ResilienceCreditCalculator({
  resilienceCreditsPerHectare,
  hectares,
  setHectares,
}: {
  resilienceCreditsPerHectare: number;
  hectares: number;
  setHectares: (hectares: number) => void;
}) {
  const [pricePerCredit, setPricePerCredit] = useState<number>(0);

  const calculatedCredits = hectares * resilienceCreditsPerHectare;
  const totalValue = calculatedCredits * pricePerCredit;

  return (
    <div className="w-full flex flex-col items-center pl-2 pr-8">
      <div className="w-full flex justify-between mb-4">
        <div className="w-1/2 pr-2 flex flex-col items-end">
          <label className="block text-right label-large">
            Hectares Conserved:
          </label>
          <input
            type="number"
            value={hectares}
            onChange={(e) => setHectares(Number(e.target.value))}
            className="w-1/2 border p-1 text-lg text-right lining-nums"
          />
        </div>
        <div className="w-1/2 pl-2 flex flex-col items-end">
          <label className="block text-right label-large">
            Price per Credit:
          </label>
          <div className="w-1/2 flex items-center border p-1">
            <span className="mr-1">$</span>
            <input
              type="number"
              value={pricePerCredit}
              onChange={(e) => setPricePerCredit(Number(e.target.value))}
              className="w-full text-lg text-right lining-nums"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-2">
        <div className="w-1/2 pr-2">
          <label className="block text-right label-large">
            Calculated Credits:
          </label>
          <h6 className="w-full text-lg font-bold text-shoreline-dark text-right">
            {calculatedCredits.toFixed(0)}
          </h6>
        </div>
        <div className="w-1/2 pl-2">
          <label className="block text-right label-large">Total Value:</label>
          <h6 className="w-full text-lg font-bold text-shoreline text-right">
            ${kFormatter(totalValue)}
          </h6>
        </div>
      </div>
    </div>
  );
}

function generateComparisonText(
  studyUnitValue: number,
  countryValue: number,
  worldValue: number,
  countryName: string,
  creditsType: "people" | "property",
): string {
  const studyToCountry = ((studyUnitValue - countryValue) / countryValue) * 100;
  const studyToWorld = ((studyUnitValue - worldValue) / worldValue) * 100;

  let text = `This study unit has potential for ${studyUnitValue.toFixed(
    0,
  )} ${creditsType} resilience credits available. `;

  if (Math.abs(studyToCountry) < 1) {
    text += `It is on par with the ${countryName} median of ${countryValue.toFixed(
      0,
    )} credits. `;
  } else {
    text += `It is ${
      Math.abs(studyToCountry) > 1000
        ? "much"
        : `${Math.abs(studyToCountry).toFixed(0)}%`
    } ${
      studyToCountry > 0 ? "higher" : "lower"
    } than the ${countryName} median of ${countryValue.toFixed(0)} credits. `;
  }

  if (Math.abs(studyToWorld) < 1) {
    text += ` It is also on par with the world median of ${worldValue.toFixed(
      0,
    )} credits.`;
  } else {
    text += ` Compared to the world median of ${worldValue.toFixed(
      0,
    )} credits, it is ${
      Math.abs(studyToWorld) > 1000
        ? "much"
        : `${Math.abs(studyToWorld).toFixed(0)}%`
    } ${studyToWorld > 0 ? "higher" : "lower"}.`;
  }

  return text;
}

const sum = (acc: number, cur: number) => acc + cur;

const reducers = {
  sum: (values: number[]) => values.reduce(sum, 0),
};

const getStat = (
  metric: string,
  selectedFeatures: MapboxGeoJSONFeature[],
  distinct = false,
) => {
  const values = selectedFeatures.map((x) => x.properties?.[metric]);
  const uniqueValues = distinct ? [...new Set(values)] : values;
  return reducers.sum(uniqueValues);
};

const VERRA_LAYER_NAMES = {
  people: [
    LayerName.TESSELA_RPS_VERRA_PEOPLE_RESILIENCE_CREDITS,
    LayerName.TESSELA_BOUNDS_VERRA_PEOPLE_RESILIENCE_CREDITS,
  ],
  property: [
    LayerName.TESSELA_RPS_VERRA_PROPERTY_RESILIENCE_CREDITS,
    LayerName.TESSELA_BOUNDS_VERRA_PROPERTY_RESILIENCE_CREDITS,
  ],
};

function SelectedFeaturesVerraPanel({
  selectedFeatures,
  layersToggle,
  toggleLayer,
}: {
  selectedFeatures: MapboxGeoJSONFeature[];
  layersToggle: Record<string, boolean>;
  toggleLayer: (layer: LayerName) => void;
}) {
  const [hectares, setHectares] = useState<number>(0);
  const selectedCountries = useMemo(() => {
    return [
      ...new Set(
        selectedFeatures.map((feature) => feature.properties?.Country),
      ),
    ];
  }, [selectedFeatures]);

  const filteredPopBenData = useMemo(() => {
    return popBenToMangroveRatio.countries.filter((country) =>
      selectedCountries.includes(country.Country),
    );
  }, [selectedCountries]);

  const mangroves2015 = useMemo(
    () => getStat(`Mang_Ha_2015`, selectedFeatures),
    [selectedFeatures],
  );

  const stock2015 = useMemo(
    () => getStat(`Ben_Stock_2015`, selectedFeatures),
    [selectedFeatures],
  );

  const pop2015 = useMemo(
    () => getStat(`Ben_Pop_2015`, selectedFeatures),
    [selectedFeatures],
  );

  const peopleResilinceCredits = useMemo(
    () => pop2015 / mangroves2015,
    [mangroves2015, pop2015],
  );

  const propertyResilinceCredits = useMemo(
    () => stock2015 / mangroves2015,
    [mangroves2015, stock2015],
  );

  const mode: "people" | "property" = layersToggle[
    LayerName.TESSELA_RPS_VERRA_PEOPLE_RESILIENCE_CREDITS
  ]
    ? "people"
    : "property";

  const handleSwitchUpdate = () => {
    Object.entries(VERRA_LAYER_NAMES).forEach(([key, value]) => {
      toggleLayer(value[0]);
      toggleLayer(value[1]);
    });
  };

  const peopleChartData = useMemo(() => {
    const country = selectedCountries[0]; // Assuming we're dealing with one country at a time
    const countryRatio =
      filteredPopBenData.find((c) => c.Country === country)?.ratio || 0;

    return [
      { name: "Study Unit", value: peopleResilinceCredits },
      { name: country, value: countryRatio },
      { name: "World", value: popBenToMangroveRatio.world },
    ];
  }, [selectedCountries, filteredPopBenData, peopleResilinceCredits]);

  const peopleComparisonText = useMemo(() => {
    const country = selectedCountries[0];
    const countryRatio =
      filteredPopBenData.find((c) => c.Country === country)?.ratio || 0;
    return generateComparisonText(
      peopleResilinceCredits,
      countryRatio,
      popBenToMangroveRatio.world,
      country,
      "people",
    );
  }, [selectedCountries, filteredPopBenData, peopleResilinceCredits]);

  const propertyChartData = useMemo(() => {
    const country = selectedCountries[0];
    const countryRatio =
      stockBenToMangroveRatio.countries.find((c) => c.Country === country)
        ?.ratio || 0;

    return [
      { name: "Study Unit", value: propertyResilinceCredits },
      { name: country, value: countryRatio },
      { name: "World", value: stockBenToMangroveRatio.world },
    ];
  }, [selectedCountries, propertyResilinceCredits]);

  const propertyComparisonText = useMemo(() => {
    const country = selectedCountries[0];
    const countryRatio =
      stockBenToMangroveRatio.countries.find((c) => c.Country === country)
        ?.ratio || 0;
    return generateComparisonText(
      propertyResilinceCredits,
      countryRatio,
      stockBenToMangroveRatio.world,
      country,
      "property",
    );
  }, [selectedCountries, propertyResilinceCredits]);

  return (
    <>
      <TemplateMetricContainer
        icon={layerGroups[LayerGroupName.BenefitsPerHectare].IconComponent}
        description="Verra Resilience Credits are calculated based on the benefits to people and property per hectare of restored and protected mangroves."
        title="Verra Resilience Credits"
      >
        <div className="flex w-full justify-center">
          <MultiSwitch
            onChange={handleSwitchUpdate}
            selection={mode}
            theme="light"
          >
            <MultiSwitchOption title="People" value="people" position="left" />
            <MultiSwitchOption
              title="Property"
              value="property"
              position="right"
            />
          </MultiSwitch>
        </div>
        <div className="flex align-center flex-wrap w-full">
          {mode === "people" && (
            <>
              <h5 className="ml-2 mb-1 mt-2 w-full">
                People Resilience Credits:
              </h5>
              <p className="w-3/5 italic text-right">
                People protected by Mangroves:
              </p>
              <p className="ml-2 lining-nums">{kFormatter(pop2015)}</p>
              <p className="w-3/5 italic text-right">
                ÷ Hectares of Mangroves:
              </p>
              <p className="ml-2 lining-nums">{kFormatter(mangroves2015)}</p>
              <div className="w-3/5 my-1 -translate-x-2 mx-auto pr-4 self-center	 border-solid border-t-[1px] border-trench" />
              <p className="w-3/5 italic text-right">
                Verra People Resilience Credits:
              </p>
              <p className="ml-2 lining-nums">
                <b>{kFormatter(peopleResilinceCredits)}</b>
              </p>
              <p className="ml-2 my-4 lining-nums">{peopleComparisonText}</p>
              <div className="h-4" />
              <ResponsiveContainer width="100%" height={220}>
                <BarChart
                  data={peopleChartData}
                  margin={{ top: 20, right: 30, left: 12, bottom: 5 }}
                >
                  <XAxis
                    dataKey="name"
                    stroke="var(--shoreline-dark)"
                    angle={0}
                    interval={0}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    stroke="var(--shoreline-dark)"
                    tickFormatter={(value) => kFormatter(value) as string}
                    label={{
                      value: "People Resilience Credits",
                      angle: -90,
                      fill: "var(--shoreline-dark)",
                      dx: -24,
                    }}
                  />
                  <Bar dataKey="value" name="Resilience Credits">
                    {peopleChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          index === 0 ? "var(--red-tide)" : "var(--shoreline)"
                        }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <h5 className="ml-2 mt-4 mb-2 w-full">
                People Resilience Credit Calculator:
              </h5>
              <ResilienceCreditCalculator
                hectares={hectares}
                setHectares={setHectares}
                resilienceCreditsPerHectare={peopleResilinceCredits}
              />
            </>
          )}
          {mode === "property" && (
            <>
              <h5 className="ml-2 mb-1 mt-2 w-full">
                Property Resilience Credits:
              </h5>
              <p className="w-3/5 italic text-right">
                Property Value protected:
              </p>
              <p className="ml-2 lining-nums">${kFormatter(stock2015)}</p>
              <p className="w-3/5 italic text-right">
                ÷ Hectares of Mangroves:
              </p>
              <p className="ml-2 lining-nums">{kFormatter(mangroves2015)}</p>
              <div className="w-3/5 my-1 -translate-x-2 mx-auto pr-4 self-center	 border-solid border-t-[1px] border-trench" />
              <p className="w-3/5 italic text-right">
                Verra Property Resilience Credits:
              </p>
              <p className="ml-2 lining-nums">
                <b>{kFormatter(propertyResilinceCredits)}</b>
              </p>
              <p className="ml-2 my-4 lining-nums">{propertyComparisonText}</p>
              <ResponsiveContainer width="100%" height={220}>
                <BarChart
                  data={propertyChartData}
                  margin={{ top: 20, right: 30, left: 12, bottom: 5 }}
                >
                  <XAxis
                    dataKey="name"
                    stroke="var(--shoreline-dark)"
                    angle={0}
                    interval={0}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    stroke="var(--shoreline-dark)"
                    tickFormatter={(value) => kFormatter(value) as string}
                    label={{
                      value: "Property Resilience Credits",
                      angle: -90,
                      fill: "var(--shoreline-dark)",
                      dx: -24,
                    }}
                  />
                  <Bar dataKey="value" name="Resilience Credits">
                    {propertyChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          index === 0 ? "var(--red-tide)" : "var(--shoreline)"
                        }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <h5 className="ml-2 mt-4 mb-2 w-full">
                Property Resilience Credit Calculator:
              </h5>
              <ResilienceCreditCalculator
                hectares={hectares}
                setHectares={setHectares}
                resilienceCreditsPerHectare={propertyResilinceCredits}
              />
            </>
          )}
        </div>
      </TemplateMetricContainer>
    </>
  );
}

export default SelectedFeaturesVerraPanel;
