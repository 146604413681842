import { MultiSwitch, MultiSwitchOption } from "components/multi-switch";
import { LAYERS, layersByGroup } from "layers/layers";
import { useCallback, useMemo } from "react";
import {
  ConfigurableLayer,
  ConfigurableLayerMap,
  LayerGroupName,
  LayerName,
  SelectLayer,
  SlideLayer,
  ToggleLayer,
} from "types/dataModel";
import "./legend-layer-selector.css";
import { DiscreteColorSizeScale } from "layers/colormaps/colormaps";

interface LegendLayerSelectorProps {
  layersToggle: LayerSelection;
  toggleLayer: (layer: string) => void;
  layerGroup: LayerGroupName;
}

const getLayerGroup = (layerGroup: LayerGroupName) =>
  layersByGroup[layerGroup] as ConfigurableLayerMap;

const getLayerConfig = <T extends ConfigurableLayer>(
  layerGroup: LayerGroupName,
  layerKey: LayerName,
) => getLayerGroup(layerGroup)[layerKey] as T;

type GroupedLayers = Partial<Record<string, LayerName[]>>;

interface LayerSelection {
  [key: string]: boolean;
}

export const LegendLayerSwitch = ({
  layersToggle,
  toggleLayer,
  layerGroup,
}: LegendLayerSelectorProps) => {
  const slideLayerKeys = useMemo(() => {
    return (Object.keys(layersByGroup[layerGroup]) as LayerName[]).filter(
      (layerKey) =>
        getLayerConfig<SlideLayer>(layerGroup, layerKey)?.hasOwnProperty(
          "slideKey",
        ),
    );
  }, [layerGroup]);

  const selectLayerKeys = useMemo(() => {
    return (Object.keys(layersByGroup[layerGroup]) as LayerName[]).filter(
      (layerKey) =>
        getLayerConfig<SelectLayer>(layerGroup, layerKey)?.hasOwnProperty(
          "selectKey",
        ),
    );
  }, [layerGroup]);

  const allSwitchableLayerKeys = useMemo(
    () => [...slideLayerKeys, ...selectLayerKeys],
    [slideLayerKeys, selectLayerKeys],
  );

  const switchType = useMemo(
    () =>
      slideLayerKeys.length
        ? "slide"
        : selectLayerKeys.length
        ? "select"
        : null,
    [selectLayerKeys.length, slideLayerKeys.length],
  );

  const switchLayers = useMemo(() => {
    const groupedLayers: GroupedLayers = allSwitchableLayerKeys?.reduce(
      (acc, layerKey) => {
        const key =
          getLayerConfig<SlideLayer>(layerGroup, layerKey)?.slideKey ??
          "not-slide";
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(layerKey);
        return acc;
      },
      {} as GroupedLayers,
    );
    return Object.fromEntries(
      Object.entries(groupedLayers).map(([key, layers]) => [
        key,
        layers
          ?.sort((a, b) => {
            const aPos = getLayerConfig<SlideLayer>(layerGroup, a)
              ?.slidePosition;
            const bPos = getLayerConfig<SlideLayer>(layerGroup, b)
              ?.slidePosition;
            return aPos === bPos ? 0 : aPos === "left" ? -1 : 1;
          })
          .filter((layer, index, self) => {
            return (
              index ===
              self.findIndex((l) =>
                switchType === "slide"
                  ? getLayerConfig<SlideLayer>(layerGroup, l)?.slidePosition ===
                    getLayerConfig<SlideLayer>(layerGroup, layer)?.slidePosition
                  : getLayerConfig<SelectLayer>(layerGroup, l)
                      ?.selectPosition ===
                    getLayerConfig<SelectLayer>(layerGroup, layer)
                      ?.selectPosition,
              )
            );
          }),
      ]),
    );
  }, [allSwitchableLayerKeys, layerGroup, switchType]);

  const handleSwitchUpdate = useCallback(
    (key: string) => (value: string | number) => {
      if (value === "compare") {
        switchLayers[key]?.forEach((layer) => {
          if (!layersToggle[layer]) {
            toggleLayer(layer);
          }
        });
      } else {
        console.log("switchLayers", switchLayers);
        if (switchType === "select") {
          switchLayers[key]?.forEach((layer) => {
            toggleLayer(layer);
          });
        } else {
          switchLayers[key]?.forEach((layer) => {
            if (layer === value && !layersToggle[layer]) {
              toggleLayer(layer);
            } else if (layer !== value && layersToggle[layer]) {
              toggleLayer(layer);
            }
          });
        }
      }
    },
    [layersToggle, switchLayers, switchType, toggleLayer],
  );

  if (Object.keys(switchLayers).length === 0) {
    return null;
  }

  return (
    <div className="legend-layer-switch">
      {switchType === "slide" &&
        Object.entries(switchLayers).map(([key, layers]) => (
          <div key={key}>
            {layers?.length && (
              <>
                <div className="flex w-full justify-center">
                  <MultiSwitch
                    onChange={handleSwitchUpdate(key)}
                    selection={
                      layersToggle[layers[0]] && layersToggle[layers[1]]
                        ? "compare"
                        : layersToggle[layers[0]]
                        ? layers[0]
                        : layersToggle[layers[1]]
                        ? layers[1]
                        : layers[0]
                    }
                  >
                    <MultiSwitchOption
                      title={
                        getLayerConfig<SlideLayer>(layerGroup, layers[0])
                          ?.slideLabel ?? ""
                      }
                      value={layers[0]}
                      position="left"
                    />
                    <MultiSwitchOption
                      title="both"
                      value="compare"
                      position="center"
                    />
                    <MultiSwitchOption
                      title={
                        getLayerConfig<SlideLayer>(layerGroup, layers[1])
                          ?.slideLabel ?? ""
                      }
                      value={layers[1]}
                      position="right"
                    />
                  </MultiSwitch>
                </div>
                <div className="h-[1px] mt-2 w-full border-t border-dashed border-gray-200" />
              </>
            )}
          </div>
        ))}
      {switchType === "select" &&
        Object.entries(switchLayers).map(([key, layers]) => (
          <div key={key}>
            {layers?.length && (
              <>
                <div className="flex w-full justify-center">
                  <MultiSwitch
                    onChange={handleSwitchUpdate(key)}
                    selection={
                      layersToggle[layers[0]]
                        ? layers[0]
                        : layersToggle[layers[1]]
                        ? layers[1]
                        : layers[0]
                    }
                  >
                    <MultiSwitchOption
                      title={
                        getLayerConfig<SelectLayer>(layerGroup, layers[0])
                          ?.selectLabel ?? ""
                      }
                      value={layers[0]}
                      position="left"
                    />
                    <MultiSwitchOption
                      title={
                        getLayerConfig<SelectLayer>(layerGroup, layers[1])
                          ?.selectLabel ?? ""
                      }
                      value={layers[1]}
                      position="right"
                    />
                  </MultiSwitch>
                </div>
                <div className="h-[1px] mt-2 w-full border-t border-dashed border-gray-200" />
              </>
            )}
          </div>
        ))}
    </div>
  );
};

export const LegendLayerToggle = ({
  layersToggle,
  toggleLayer,
  layerGroup,
}: LegendLayerSelectorProps) => {
  const togglableLayers = useMemo(() => {
    const layers = (
      Object.keys(layersByGroup[layerGroup]) as LayerName[]
    ).filter(
      (layerKey) =>
        getLayerConfig(layerGroup, layerKey)?.hasOwnProperty("toggleKey"),
    );
    const groupedLayers: GroupedLayers = layers.reduce((acc, layerKey) => {
      const toggleKey =
        getLayerConfig<ToggleLayer>(layerGroup, layerKey)?.toggleKey ??
        "not-shared";
      if (!acc[toggleKey]) {
        acc[toggleKey] = [];
      }
      acc[toggleKey].push(layerKey);
      return acc;
    }, {} as GroupedLayers);
    return groupedLayers;
  }, [layerGroup]);

  const legendColor = useMemo(() => {
    const builtColorMap: Record<string, string> = {};
    Object.keys(LAYERS).forEach((key) => {
      const layerConfig = getLayerConfig<ToggleLayer>(
        layerGroup,
        key as LayerName,
      );
      if (layerConfig?.toggleKey) {
        if (layerConfig.slidePosition) {
          // Find any associated slide layers
          const associatedLayerName = Object.keys(LAYERS).find((layerName) => {
            const slideConfig = getLayerConfig<SlideLayer>(
              layerGroup,
              layerName as LayerName,
            );
            return (
              slideConfig?.slidePosition === layerConfig.slidePosition &&
              !!slideConfig?.slideKey
            );
          });
          if (associatedLayerName) {
            // check if layer is toggled
            if (layersToggle[associatedLayerName as LayerName]) {
              const legend = LAYERS[key as LayerName].legend as InstanceType<
                typeof DiscreteColorSizeScale
              >;
              builtColorMap[key] = legend.colorRamp?.[0];
            }
          }
        } else {
          const legend = LAYERS[key as LayerName].legend as InstanceType<
            typeof DiscreteColorSizeScale
          >;
          builtColorMap[key] = legend.colorRamp?.[0];
        }
      }
    });

    return Object.keys(builtColorMap).length < 1
      ? "blue"
      : Object.keys(builtColorMap).length === 1
      ? builtColorMap[Object.keys(builtColorMap)[0]]
      : builtColorMap[Object.keys(builtColorMap)[1]];
  }, [layerGroup, layersToggle]);

  if (Object.keys(togglableLayers).length === 0) {
    return null;
  }

  return (
    <div className="legend-layer-toggle">
      {Object.entries(togglableLayers).map(
        ([key, layers]) =>
          layers && (
            <div key={key} className="mt-4">
              <label className="text-sm custom-checkbox">
                <input
                  type="checkbox"
                  checked={layersToggle[key]}
                  onChange={() => toggleLayer(key)}
                  className="mr-2"
                />
                <span
                  className="checkmark"
                  style={{
                    backgroundColor: legendColor,
                    borderColor: legendColor,
                  }}
                />
                <span className="checkmark-label">
                  {
                    getLayerConfig<ToggleLayer>(layerGroup, layers[0])
                      ?.toggleLabel
                  }
                </span>
              </label>
            </div>
          ),
      )}
    </div>
  );
};
